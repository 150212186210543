/* Keyframes for the slide animation */
@keyframes autoplay2 {
    0% { top: 100%; }
    2% { top: 0%; }
    16.66% { top: 0%; }
    18.88% { top: -100%; }
    100% { top: -100%; }
}

/* Styles for the slide container */
#slideset2 {
    height: 90vh;
    display: flex;
    flex-direction: column;
    padding-left: 5vh;
    padding-right: 5vh;
    position: relative;
    overflow: hidden;
}

/* Styles for each individual slide */
#slideset2 > * {
    position: absolute;
    margin-top: 5vh;
    margin-bottom: 5vh;
    top: 100%;
    left: 0;
    animation: 24s autoplay2 infinite ease-in-out;
}

/* Animation delay for each slide */
#slideset2 > *:nth-child(1) { animation-delay: 0s; }
#slideset2 > *:nth-child(2) { animation-delay: 4s; }
#slideset2 > *:nth-child(3) { animation-delay: 8s; }
#slideset2 > *:nth-child(4) { animation-delay: 12s; }
#slideset2 > *:nth-child(5) { animation-delay: 16s; }
#slideset2 > *:nth-child(6) { animation-delay: 20s; }