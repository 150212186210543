/* LeadsSection.css */
.slider {
  display: flex;
  animation: scroll 20s linear infinite; /* Adjust animation duration as needed */
  width: calc(100% * 9 + 10 * 8px); /* Total width of all slides plus spacing */
}

.slide {
  flex: 0 0 auto;
  margin-right: 10px; /* Adjust spacing between slides */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Move by the width of one slide */
  }
}
